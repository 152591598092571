<template>
  <div class="dashboard-editor-container">
    <template></template>
    <div class="welcome">WELCOME</div>
    <div class="system">欢迎进入<span>{{siteName}}</span>社区管理系统</div>
    <img class="img" src="../../assets/img/dashboard.png"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DashboardAdmin',
  components: {
  },
  computed: {
    ...mapGetters([
      'siteName'
    ])
  },
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
.dashboard-editor-container {
  position: relative;
  height: 100%;
  text-align: center;
}
.welcome{
  font-size:46px;
  font-family:PingFangSC-Semibold;
  font-weight:600;
  color:rgba(48,65,86,1);
  position: relative;
  top: 90px;
}
.system{
  font-size:20px;
  font-family:PingFangSC-Regular;
  font-weight:400;
  color:rgba(48,65,86,1);
  position: relative;
  top: 110px;
}
.img{
  width: 900px;
  height: 480px;
  position: relative;
  top: 180px;
}

</style>
